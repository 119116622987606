import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { Link } from 'gatsby'
import Helmet from 'react-helmet';
import './cybersec.css'

import {StripeProvider} from 'react-stripe-elements';
import {Stripe} from 'stripe.js'
import Checkout from '../components/checkout';

import CyberSec from '../pics/cyber_security_for_web_developers_in_barcelona_code_school.png'

import Contacts from '../components/contactform';

import Instalments from '../components/instalments.js'


const CyberSecForDevs = () => {

	let [courses, setCourses] = useState({
		durationdays: null,
		durationweeks: 2,
		fulldesc1:{twoday:"2-day workshop, in-person, in English, 17:00 to 21:00",oneday:"1-day workshop, in-person, in English, Saturday, 9:00-18:00"},
		fulldesc2: "Full cost 790€, paid during the registration",
		fullprice: 790,
		image: "https://barcelonacodeschool.com/static/cyber_security_for_web_developers_in_barcelona_code_school-daa23de127f0923de722b9fee578347c.png",
		priceoptions: [],
		shortdesc: "Learn how to make your websites and apps secured and protected from malicious attempts.",
		signupprice: 790,
		startdates: ['November 7-8, 17:00-21:00'],
		starttime: "9:00",
		tag: "cyberSec",
		title: "Cyber Security For Web Developers",
	})
	
	// useEffect(()=>{
	// 	let getData = async () => {
	// 		let data = await axios.get('https://barcelonacodeschool.com/events/jsProg')
	// 		setCourses(data.data)
	// 		console.log(data.data)
	// 	}
	// 	getData()
	// },[])

	let renderCourses =()=> {
		return courses.startdates.map((date,i)=>(
			<div key={i}className="whiteCard bottomSpace flex300" >
			<span>
			<h2>{date}</h2>
			<h4>{courses.title}</h4>
			<p>{courses.shortdesc}</p>
			{date.includes("March 20")&& <h2 className='highlighter'>Group is full!</h2>}
			</span>
			<span>
			<p>{	courses.fulldesc1.twoday}</p>
			<p>{courses.fulldesc2}</p>
			{!date.includes("March 20")&&<Link to="/register" state={{course: courses.title, date:date, cost:courses.signupprice, images:[courses.image]}}>
			<button style={{'marginBottom': '1em'}}>Sign up</button>
			</Link>}
			<p className='finePrint'>By signing up you agree to the <a href='/about-us/terms-and-conditions/'>Terms and Conditions</a> stated on the website.</p>
			</span>
			</div>
			))
	}


	return <div className="flexContent"
	>
	<Helmet

	title="Cyber Security For Web Developers in Barcelona Code School"
	meta={[
	{
		name: "description",
		content:
		"CyberSec practical workshop for web developers of any levels hosted in Barcelona Code School by Martin Vigo.",
	},
	{
		name: "keywords",
		content:
		"cybersec, cyber security, web security, cyber security workshop, cybersec workshop",
	},
	{
		name: "viewport",
		content: "width=device-width, initial-scale=1",
	},
	{
		property: "og:title",
		content:
		"Cyber Security For Web Developers in Barcelona Code School",
	},
	{
		property: "og:description",
		content:
		"CyberSec practical workshop for web developers of any levels hosted in Barcelona Code School by Martin Vigo.",
	},
	{
		property: "og:image",
		content:
		"https://barcelonacodeschool.com/static/cyber_security_for_web_developers_in_barcelona_code_school-daa23de127f0923de722b9fee578347c.png",
	},
	{
		property: "og:url",
		content:
		"https://barcelonacodeschool.com/static/cyber_security_for_web_developers_in_barcelona_code_school-daa23de127f0923de722b9fee578347c.png",
	},
	{ name: "twitter:card", content: "summary_large_image" },
	]}
	script={[
		{ "src": "https://js.stripe.com/v3/" }
		]}
	/>

	<h1 className='transparentContainer flex1200'>Cyber Security For Web Developers course in Barcelona Code School / on demand</h1>

	<img 
	className='banners flex1200 offsetImage'
	src={CyberSec} 
	alt='Cyber Security for Web Developers workshop in Barcelona Code School'/>


	<h2 className='transparentContainer flex1200'>Learn how to make your web applications secured and resistant to hackers!</h2>

	<div className='transparentContainer flex450'>
	<h3>Format</h3>
	<article className='whiteCard'>
	<ul className='neonList'>
	<li>Intensive in-person hands-on training</li>
	<li>8 hours of training (1-day workshop / 8 hours  or 2-day workshops / 4 hours each)</li>
	<li>The course is taught in English</li>
	<li>Learn from the experts and implement right away</li>
	<li>Certificate of completion</li>
	</ul>
	</article>
	</div>

	<article className='transparentContainer flex225'>
	<h2>Tuition</h2>
	<div className='whiteCard'>
	<h2>990€</h2>
	{/* <p>100% is paid upon registration.</p>
	<p>10% off for Barcelona Code School alumni</p> */}
	<p>This course is provided on demand, please fill out an inquiry form to request the quote</p>
	<a target='_blank' rel="noopener noreferrer" href='https://docs.google.com/forms/d/e/1FAIpQLSe9RjD2IUEfNySs5E30KFGNmuMcr7CWbszLtSO1Kh2-qgR5ZA/viewform?usp=sf_link' ><button>Request quotation</button></a>
	</div>
	</article>

	<div className='transparentContainer flex450'>
	<h3>Why do you need to learn Cyber Security?</h3>
	<div className='whiteCard'>
	<p>You already know how to build web application. But <strong className='highlighter'>do you know how to make them secure</strong>?</p> 
	<p>Learn how to <strong className='highlighter'>handle sensitive data</strong>, how to <strong className='highlighter'>strengthen your authentication system</strong>, how to <strong className='highlighter'>protect your database and server</strong> from malicious attempts, how to <strong className='highlighter'>prevent most common hacking attacks</strong>.</p>
	<p>With Cyber Security skills under your belt you will be able not only to build web apps but make them bulletproof from the penetration attacks <strong className='highlighter'>significantly increasing your value as a developer</strong>!</p>
	</div>
	</div>

	<article className="transparentContainer flex450">
	<h2>What you'll learn</h2>
	<div className='whiteCard'>
	<section className='cyberSecTools'>
	<h4><span>$_</span> Browser developer tools</h4>
	<h4><span>$_</span> Burp Suite</h4>
	<h4><span>$_</span> Nikto</h4>
	<h4><span>$_</span> WPScan</h4>
	<h4><span>$_</span> SQLMap</h4>
	<h4><span>$_</span> DIrb</h4>
	</section>
	<ul>
	<li>Overall understanding of how vulnerabilities are found and exploited</li>
	<li>Technical understanding of the most common occurring Web vulnerabilities (CSRF, XSS, SQLi, SSRF, IDOR, etc.)</li>
	<li>Learn how to avoid introducing most common occurring Web vulnerabilities</li>
	<li>Learn how to fix most common occurring Web vulnerabilities</li>
	<li>Cryptography fundamentals</li>
	<li>Tooling to identify web vulnerabilities</li>
	<li>Hardening of websites and infrastructure to reduce the impact of vulnerabilities exploitation</li>
	<li>Web technologies from cybersecurity perspective (javascript, cookies, HTTP protocol, certificates, etc.)</li>
	<li>Browser Security model</li>
	<li>Permissions & User roles (AuthN, AuthZ, principle of least privilege)</li>
	</ul>
	</div>
	</article>






	<article className="transparentContainer flex450">
	<h2>Who is this course for</h2>
	<div className='whiteCard'>
	<p>This course is suitable for the developers, from juniors to seniors, without any training in CyberSec.</p> 
	<p>To benefit the most we expect you to be familiar with such things as implementing authentication, building a server, working with database, APIs, sending emails from the server, understanding user roles, HTTP requests and responses...</p>
	<p>You will need to bring your laptop to participate in the practical tasks aimed at teaching you how to pentest your software and protect from the known attacks.</p>
	</div>
	</article>

{/*	<article className="transparentContainer flex450">
	<h2>Join the waitlist</h2>
	<div className='whiteCard'>
	<ul>
	<li>Be the first to know about the dates</li>
	<li>Get ealy bird special price</li>
	</ul>
	</div>
	</article>*/}



	<article className='transparentContainer flex300'>
	<h2>Prerequisites</h2>
	<div className='whiteCard'>
	<ul>
	<li>Javascript essentials knowledge</li>
	<li>Familiarity with how databases work at a basic level</li>
	<li>Being comfortable using the browser (developer tools, extensions)</li>
	</ul>
	</div>
	</article>




	<article className='transparentContainer flex300'>
	<h2>Why taking this course?</h2>
	<div className='whiteCard'>
	<ul>
	<li>Standout from the rest of developers with your cybersecurity knowledge</li>
	<li>Become a stronger engineer by following secure best practices</li>
	<li>Be the security Subject Matter Expert in your team</li>
	<li>Take additional responsibilities by reviewing your team’s code for vulnerabilities</li>
	<li>Mentor and educate other engineers in cybersecurity essentials</li>
	<li>Start a new cybersecurity career protecting your organization’s exposed assets</li>
	</ul>
	</div>
	</article>




	<article className='transparentContainer flex900'>
	<h2>Your mentor</h2>
	<div className='whiteCard'>
	<h2>Martin Vigo</h2>
	<h3>Security expert, researcher and educator</h3>
	<p><a href='https://www.linkedin.com/in/martinvigo/' target='blank'>Martin Vigo</a> is a security researcher and ethical hacker with a strong background in Product Security and Software Engineering who has made significant contributions to the cybersecurity community. With a focus on mobile security, identity and authentication, code reviews, and penetration testing, Martin has dedicated himself to ensuring the security of cloud-based systems.</p>

	<p>He has presented at various international conferences and has been involved in finding vulnerabilities and developing secure solutions for different organizations. As the Founder of Triskel Security, a growing security consulting company, he provides comprehensive information security solutions to clients. Martin is also recognized as the host and producer of the Spanish cybersecurity podcast "Tierra de Hackers," which covers the latest cybersecurity news and trends. </p>

	<p>With a primary interest in offensive security, he has delivered captivating presentations on various topics, including voicemail cracking for accounts takeover, exploiting password managers, OSINT (Open-Source Intelligence), leveraging Apple's Facetime for spy programs, and best practices in mobile app development. These presentations have been showcased at prestigious conferences such as DEF CON, Blackhat EU, Ekoparty, BSides Las Vegas, Kaspersky Security Analyst Summit, and Shakacon.</p>
	</div>
	</article>




	{/*<article className='transparentContainer flex225'>
	<h2>The outcomes</h2>
	<div className='whiteCard'>

	<p>After this course you will have a strong understanding of the <strong>logic</strong>, <strong>syntax</strong> and <strong>usage</strong> of JavaScript. </p>
	<p>You will be ready to move on to add these new skills to your web applications and start learning any JS library, should it be React.js, Angular, Vue, Ember or anything else.</p>
	<p>Ability to solve programming challenges with JS by splitting them into logical steps and using algorithms for finding the best solution.</p>
	</div>
	</article>

	<article className='transparentContainer flex225'>
	<h2>Tuition</h2>
	<div className='whiteCard'>
	<h2>1200€</h2>
	<p>100% is paid upon registration.</p>
	<p>After that you get the pre-course materials right away and can start working on them. They include some beginner-friendly introduction to the basic JS concepts to get familiar with them and get the proper mindset for the course.</p>
	<Instalments/>
	</div>
	</article>*/}


{/*	<article id="cohorts" className="transparentContainer flex900 flexContent">
	<h2>Access</h2>
	<div className='flexContent'>
	{renderCourses()}
	</div>
	</article>*/}
	<div className='flex450'>
	<Contacts
	title={"Inquiry about the course"}
	subject={"CyberSec fro Web Developers Inquiry"}
	/>
	</div>


{/*	<div className='flex1200'>
	<Contacts title={'Join the waitlist'} extraText={'Get notified first about the updates and secure a special early bird price!'} messagePlaceholder={'Optional: let us know about your background'} subject={'Inquiry about Cyber Security for Web Developers'} />
	</div>*/}
	</div>

}

export default CyberSecForDevs
